<template>
    <v-card class="elevation-4" :loading="loading" min-height="60vh">
        <v-card-title class="card-header page">
            <v-icon color="primary" class="mr-1">fa fa-times-circle</v-icon> Close Your Account
        </v-card-title>
        <v-card-text class="pt-3 pb-5">
            <v-alert class="mt-2" dense type="error" v-if="errors">
                {{ errors }}
            </v-alert>
            <v-row no-gutters class="mt-0" v-if="!user.on_alert">
                <v-col cols="12" md="12" class="text-center secondary--text">
                    <p class="title mt-2 mt-md-3 mb-3">Thank you for letting us serve you!</p>
                </v-col>
            </v-row>
            <v-row dense class="mt-0" v-if="!user.on_alert">
                <v-col cols="12" md="8" offset-md="2" class="secondary--text">
                    
                    <p class="subtitle-1 mb-0 font-weight-medium text-md-center" v-if="subscription.subscription.current_period_end">When should we close your account?</p>
                    
                    <div class="flex-center" v-if="subscription.subscription.current_period_end">
                        <v-radio-group v-model="closeNow" hide-details class="mt-0 mb-3">
                            <v-radio :label="'At end of current billing period: ' + endDate + '.'" :value="false" />
                            <v-radio label="Close immediately." :value="true" />
                        </v-radio-group>
                    </div>

                    <v-alert type="info" class="mt-md-3 subtitle-1 text-left lh-14 text-md-left">
                        <b>What happens next?</b><br>
                        <ul class="body-2 text-md-subtitle-1 lh-12 pl-0 pl-md-5" v-if="closeNow">
                            <li class="mt-1">All of your data and files will be deleted.</li>
                            <li class="mt-1 mt-md-0">You will no longer receive check-in messages.</li>
                            <li class="mt-1 mt-md-0">Your contacts will not be notified of the closure.</li>
                        </ul>
                        <ul class="body-2 text-md-subtitle-1 lh-12 pl-0 pl-md-5" v-else>
                            <li class="mt-1" v-if="subscription.subscription.current_period_end">You will be able to resume your subscription at any time during the remainder of your billing period.</li>
                            <li class="mt-1">Once closed, all of your data and files will be deleted.</li>
                            <li class="mt-1 mt-md-0">Once closed, you will no longer receive check-in messages.</li>
                            <li class="mt-1 mt-md-0">Your contacts will not be notified of the closure.</li>
                        </ul>
                    </v-alert>
                    <div class="flex-center">
                        <v-checkbox hide-details v-model="agreed" class="mt-0" :value="true" label="I understand, please close my account." />
                    </div>
                </v-col>
                <v-col cols="12" class="text-center pt-3">
                    <v-btn :disabled="!agreed" :loading="closing" color="primary" @click.native="closeAccount()">Close My Account</v-btn>
                </v-col>
                <v-col cols="12" class="text-center">
                    <v-divider class="mt-3 mb-5" />
                    <v-btn text outlined @click.native="$emit('cancelclose')">Cancel</v-btn>
                </v-col>
            </v-row>
            <v-row dense class="mt-0" v-else>
                <v-col cols="12" md="8" offset-md="2" class="secondary--text">
                    
                    <v-alert type="error" dense>
                        <p class="subtitle-1 mb-0 font-weight-medium">You are still on active alert!</p>
                    </v-alert>
                    
                    <p class="subtitle-1 mb-3 mt-3 text-center lh-13">
                        You must first close your alert by responding to your check-in message with your phone or checking in from your dashboard.
                    </p>

                    <p class="subtitle-1 text-center lh-13">
                        You can close your account once your check-in has been responded to.
                    </p>

                    <p class="text-center">
                        <v-btn rounded color="primary" to="/app">Go to Dashboard</v-btn>
                    </p>
                    
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
    
import { mapState, mapMutations } from 'vuex'

export default {
    name: 'UserCloseAccount',
    components: {
    },
    computed: {
        ...mapState(['user']),
    },
    data: () => ({
        agreed: null,
        closed: false,
        closeNow: false,
        closing: false,
        endDate: 'loading...',
        errors: null,
        loading: false,
        saving: false,
        subscription: {
            subscription: {
                current_period_end: null
            }
        }
    }),
    methods: {
        ...mapMutations(['setUser']),
        initData(){
            this.$appFn.setTitle("Close Your Account - I'm Okay", true);
             window.gtag('event', 'page_view', {
                page_title: "Close Your Account - I'm Okay",
                page_location: location.pathname,
            })
            this.getSubscription();
        },
        getSubscription(){
			this.loading = true;
            let request = {
                endpoint: '/subscription', 
                method: 'get',
                data: {},
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
					this.subscription = res.data;
                    this.endDate = this.$moment.unix(this.subscription.subscription.current_period_end).tz(this.user.timezone).format('MMMM Do YYYY')
				}
            }).finally(()=>{
                this.loading = false;
            });
		},
        closeAccount(){
            if(!this.agreed){
                return;
            }
            this.closing = true;
            this.errors = null;
            let request = {
                endpoint: '/subscription/close', 
                method: 'post',
                data: {
                    now: this.closeNow
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    let text = (this.closeNow) ? 'Your subscription has been canceled.' 
                        : 'Your subscription will cancel soon!';
                    this.$eventHub.$emit('snackBar', {
                        text: text
                    });
                    if(this.closeNow){
                        this.setUser({});
                        window.localStorage.clear();
                        this.$eventHub.$emit('snackBar', {
                            text: 'Your account has been closed!'
                        });
                        this.$appFn.trackEvent({ action: 'closed_account', options: {
                            action_name: 'Closed account immediately'
                        }});
                        this.$router.push('/auth/logout?closed');
                    }else{
                        this.$appFn.trackEvent({ action: 'closed_account', options: {
                            action_name: 'Closed account after billing period ends'
                        }});
                        if(this.subscription.subscription.current_period_end){
                            this.$router.push('/app/subscription?canceled');
                        }else{
                            // user wasn't subscribed
                            this.$router.push('/auth/logout?closed');
                        }
                    }
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.closing = false;
            });
        },
        handleErrorResponse(res){
            this.errors = '';
            for(let i in res.data.errors){
                this.errors += res.data.errors[i];
            }
        },
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>