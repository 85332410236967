<template>
    <v-card class="elevation-1 mt-3" :min-height="(action) ? '300' : ''">
        <v-card-title class="card-header page list-header">
            <v-icon color="primary" class="mr-1">fa fa-user-cog</v-icon> Account Settings
        </v-card-title>
        <v-card-text class="px-0 pb-1" v-if="ready">
            <v-row>
                <v-col cols="12">
                    <v-list class="pt-0 pb-0 no-truncate">
                        <v-list-item @click="action='email'" class="pl-3">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <v-icon color="secondary">fa fa-envelope</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text">{{ user.email }}</v-list-item-title>
                                <v-list-item-subtitle>Change your email address.</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon small>fa fa-edit</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item @click="action='password'" class="pl-3">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <v-icon color="secondary">fa fa-user-lock</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text">Change Your Password</v-list-item-title>
                                <v-list-item-subtitle class="d-none">Modify your login credentials.</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon small>fa fa-edit</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item @click="action='show_geo'" class="pl-3">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <v-icon color="secondary">fa fa-map-marker-alt</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text">Share Last Log-in Location</v-list-item-title>
                                <v-list-item-subtitle>Shown only to contacts if you fail to check in.</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <!-- <v-icon small>fa fa-edit</v-icon> -->
                                <v-switch dense hide-details readonly @click.prevent="action='show_geo'" v-model="user.show_geo" color="success" />
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item @click="$emit('closeaccount')" class="pl-3">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <v-icon color="secondary">fa fa-times-circle</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text">Close Your Account</v-list-item-title>
                                <v-list-item-subtitle>Cancel your subscription and erase all your data.</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon small>fa fa-share</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item @click="$router.push('/auth/logout')" class="pl-3">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <v-icon class="pl-1" color="secondary">fa fa-sign-out</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text">Log Out</v-list-item-title>
                                <v-list-item-subtitle>Sign-out of current session.</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon small>fa fa-share</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-card-text>
        <v-expand-transition>
            <v-card
                v-if="action"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;"
            >
                <v-card-title class="card-header page">
                    <v-icon color="primary" class="mr-1">fa fa-user-cog</v-icon> {{ updateTitle }}
                </v-card-title>
                <v-card-text class="pt-4">
                    <v-alert class="mt-0" dense type="error" v-if="errors">
                        {{ errors }}
                    </v-alert>
                    <template v-if="action=='email'">
                        <template v-if="emailChanged">
                            <v-alert type="success">
                                Check your email for a message containing a link to confirm your new change.
                            </v-alert>
                        </template>
                        <template v-else>
                            <v-text-field
                                :autofocus="!$vuetify.breakpoint.smAndDown" 
                                v-model="email"
                                outlined
                                label="New Email Address"
                                type="email"
                                placeholder="Ex: my.name@domain.com"
                                :rules="[rules.required]"
                                validate-on-blur
                                class="mb-0 mx-auto mt-md-4 mt-lg-6"
                                block
                                style="max-width: 400px;"
                                v-on:keyup.enter="(canSubmit) ? save() : ''"
                            ></v-text-field>
                            <p class="text-center caption">Your email will not change until you verify your new address.</p>
                        </template>
                    </template>
                    <template v-if="action=='password'">
                        <v-row no-gutters>
                            <v-col cols="12" lg="12">
                                <v-text-field
                                    v-model="password"
                                    type="password"
                                    :autofocus="!$vuetify.breakpoint.smAndDown" 
                                    block
                                    label="New Password"
                                    placeholder="Ex: partyPants$12"
                                    outlined
                                    style="max-width: 350px;"
                                    class="mx-auto"
                                    :rules="[rules.required, rules.minlength]"
                                    :color="(validPassword) ? 'success' : ''"
                                    :append-icon="(validPassword) ? 'fa fa-check-circle' : ''"
                                    validate-on-blur
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="12">
                                <v-text-field
                                    v-model="passwordConfirmation"
                                    type="password"
                                    outlined
                                    label="Re-Type Password"
                                    placeholder="Ex: partyPants$12"
                                    @blur="checkPassMatch()"
                                    :rules="[rules.required]"
                                    validate-on-blur
                                    style="max-width: 350px;"
                                    class="mx-auto"
                                    :error-messages="passwordError"
                                    :color="(validPasswordConfirm) ? 'success' : ''"
                                    :append-icon="(validPasswordConfirm) ? 'fa fa-check-circle' : ''"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-if="action=='show_geo'">
                        <v-row no-gutters>
                            <v-col cols="12" lg="12" class="text-center">
                                <div class="flex-center mt-5">
                                    <v-switch color="success" label="Share Last Login Location'" v-model="user.show_geo" class="ma-0 pa-0" hide-details></v-switch>
                                </div>
                                <p class="body-2 mt-5">Your last-known location is only shared to your emergency contacts if you fail to check in.</p>
                            </v-col>
                        </v-row>
                    </template>
                </v-card-text>
                <v-card-actions class="card-actions cnc-actions mt-1">
                    <template v-if="emailChanged">
                        <v-spacer />
                        <v-btn color="primary" class="px-5" @click.native="emailChanged=false; action=null">Got it!</v-btn>
                        <v-spacer />
                    </template>
                    <template v-else>
                        <v-btn text outlined @click.native="action=null">Cancel</v-btn>
                        <v-spacer />
                        <v-btn color="primary" :loading="saving" :disabled="!canSubmit" class="px-5" @click.native="save()">{{ saveBtnText }}</v-btn>
                    </template>
                </v-card-actions>
            </v-card>
        </v-expand-transition>
        <Confirm v-if="action=='asdf'" title="Changing Timezones" subtitle="Changing timezones may affect your next check-in time." msg="You can view the time of your next check-in on your dashboard." confirmBtnText="Got it!" @confirmed="tzWarn=false" @closed="tzWarn=false" />
    </v-card>
</template>
<script>
    
import { mapState } from 'vuex'
import Confirm from '@/components/common/Confirm'

export default {
    name: 'UserProfileUpdate',
    props: {
    },
    components: {
        Confirm
    },
    computed: {
        ...mapState(['user']),
        canSubmit(){
            if(this.action=='password'){
                return this.validPassword && this.validPasswordConfirm;
            }
            if(this.action=='email'){
                return this.email !== this.user.email && this.validEmail;
            }
            return true;
            // return this.item.name.length && this.item.phone.length == 14;
        },
        saveBtnText(){
            if(this.action == 'email') return 'Update Email';
            if(this.action == 'password') return 'Change Password';
            return 'Save Changes';
        },
        updateTitle(){
            if(this.action == 'email') return 'Update Email Address';
            if(this.action == 'password') return 'Change Your Password';
            if(this.action == 'show_geo') return 'Share Last Login Location';

            return 'Profile';
        },
        validEmail(){
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(this.email);
        },
        validPassword(){
            return this.password && this.password.length > 5;
        },
        validPasswordConfirm(){
            return this.passwordConfirmation && this.passwordConfirmation == this.password;
        },
    },
    data: () => ({
        action: null,
        email: null,
        emailChanged: false,
        errors: null,
        password: '',
        passwordConfirmation: '',
        passwordError: null,
        ready: false,
        rules: {
            required: value => !!value || 'Required.',
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        },
        saving: false,
    }),
    methods: {
        initData(){
            this.email = this.user.email;
            this.ready = true;
        },       
        handleErrorResponse(res){
            this.errors = '';
            for(let i in res.data.errors){
                this.errors += res.data.errors[i];
            }
        },
        checkPassMatch(){
            this.passwordError = null;
            if(this.password !== this.passwordConfirmation){
                this.passwordError = 'Passwords do not match.';
            }
        },
        save(){
            if(this.action == 'password'){
                this.updatePassword();
            }else if(this.action == 'show_geo'){
                this.updateShowGeo();
            }else{
                this.updateEmail();
            }
        },
        updateShowGeo(){
            this.saving = true;
            this.errors = null;
            let request = {
                endpoint: '/profile', 
                method: 'post',
                data: {
                    show_geo: this.user.show_geo,
                    _method: 'put'
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.$eventHub.$emit('refreshUser');
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your location sharing has been updated!'
                    });
                    this.action = null;
                    this.$appFn.trackEvent({ action: 'user_setting_update', options: {
                        action_name: (this.user.show_geo) ? 'Profile: Enabled geolocation' : 'Profile: Disabled geolocation'
                    }});
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.saving = false;
            });
        },
        updatePassword(){
            this.saving = true;
            this.errors = null;
            let request = {
                endpoint: '/auth/password', 
                method: 'post',
                data: {
                    password: this.password,
                    password_confirmation: this.passwordConfirmation,
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your password has been updated!'
                    });
                    this.action = null;
                    this.password = '';
                    this.passwordConfirmation = '';
                    this.$appFn.trackEvent({ action: 'user_setting_update', options: {
                        action_name: 'Profile: Updated password'
                    }});
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.saving = false;
            });
        },
        updateEmail(){
            this.saving = true;
            this.errors = null;
            let request = {
                endpoint: '/auth/email', 
                method: 'post',
                data: {
                    email: this.email,
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Email confirmation has been sent.'
                    });
                    this.$appFn.trackEvent({ action: 'user_setting_update', options: {
                        action_name: 'Profile: Updated email address'
                    }});
                    this.emailChanged = true;
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.saving = false;
            });
        }
    },
    mounted(){
		this.initData();
	},
    watch: {
        action(){
            this.errors = null;
        }
    }
}
</script>