<template>
    <div>
        <v-toolbar class="sub-bar">
            <v-toolbar-title>
                <v-icon>fa fa-user-cog</v-icon> Account Settings
            </v-toolbar-title>
            <v-btn v-if="!closing" to="/auth/logout" class="hidden-sm-and-down" absolute right small rounded color="primary"><v-icon small class="mr-1">fa fa-sign-out</v-icon> Log Out</v-btn>
        </v-toolbar>
        <v-row class="sub-cont">
            <v-col cols="3" class="hidden-sm-and-down pr-0">
                <LeftNav />
            </v-col>
            <v-col cols="12" md="9" class="pt-lg-1">
                <v-row :dense="$vuetify.breakpoint.lgAndUp">
                    <template v-if="!closing">
                        <v-col cols="12" lg="12" class="pt-3" v-if="ready">
                            <Update :item="item" @updated="handleUpdate($event)" />
                            <Security @closeaccount="closing=true" />
                        </v-col>
                    </template>
                    <template v-else>
                        <v-col cols="12" class="pt-3">
                            <CloseAccount @cancelclose="handleCancelClose()" />
                        </v-col>
                    </template>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    
import { mapState } from 'vuex'
import CloseAccount from '@/components/user/profile/CloseAccount'
import Security from '@/components/user/profile/Security'
import Update from '@/components/user/profile/Update'
import LeftNav from '@/components/ui/LeftNav';

export default {
    name: 'UserManageAccount',
    components: {
        CloseAccount,
        Security,
        Update,
        LeftNav
    },
    computed: {
        ...mapState(['user']),
    },
    data: () => ({
        closing: false,
        defaultCard: null,
        item: {},
        ready: false,
    }),
    methods: {
        initData(){
            this.$appFn.setTitle("Manage Account - I'm Okay", true);
            if(this.$route.params.close){
                this.closing = true;
            }
            this.item = JSON.parse(JSON.stringify(this.user));
            this.ready = true;
            if(window.location.href.indexOf('?close') !== -1){
                this.closing = true;
            }
        },
        handleUpdate(data){
            this.item = data;
        },
        handleCancelClose(){
            this.closing=false;
            if(this.$route.params.close){
                this.$router.push('/app/subscription');
            }
        },
        updateDefaultCard(){
            // this.loading = true;
            let request = {
                endpoint: '/subscription', 
                method: 'get',
                data: {},
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.defaultCard = res.data.payment_method.id;
				}
            }).finally(()=>{
                // this.loading = false;
            });
        }
    },
    mounted(){
		this.initData();
	},
    watch: {
        closing(val){
            if(val){
                setTimeout(() => {
                    this.$vuetify.goTo('#imokay-web',{container: 'body'});
                }, 100);
            }
        }
    }
}
</script>