<template>
    <v-card class="elevation-1">
        <v-card-title class="card-header page list-header">
            <v-icon color="primary" class="mr-1">fa fa-user-shield</v-icon> Personal Information
        </v-card-title>
        <v-card-text class="px-0 pb-1" v-if="ready">
            <v-alert class="mt-2" dense type="error" v-if="errors">
                {{ errors }}
            </v-alert>
            <v-row>
                <v-col cols="12">
                    <v-list class="pt-0 pb-1 no-truncate">
                        <v-list-item @click="action='name'" class="pl-3">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <v-icon color="secondary">fa fa-id-card</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text">{{ user.name }}</v-list-item-title>
                                <v-list-item-subtitle>Name displayed to contacts.</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon small>fa fa-edit</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item @click="action='phone'" class="pl-3">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <v-icon color="secondary">fa fa-phone</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text">{{ user.phone }}</v-list-item-title>
                                <v-list-item-subtitle>Send check-ins to this number.</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon small>fa fa-edit</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item @click="action='photo'" class="pl-3">
                            <v-list-item-avatar rounded>
                                    <v-img class="img-border" v-if="user.image" :src="user.image" />
                                    <v-icon v-else color="secondary">fa fa-camera</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text">{{ (!user.image) ? 'Add a Photo' : 'Your Photo' }}</v-list-item-title>
                                <v-list-item-subtitle>Displayed to emergency contacts.</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon small>fa fa-edit</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item @click="action='timezone'" class="pl-3">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <v-icon color="secondary">fa fa-clock</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium body-2 secondary--text">{{ thisTimezone }}</v-list-item-title>
                                <v-list-item-subtitle>Your local time is {{ $moment.tz(user.timezone).format('h:mma') }}.</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon small>fa fa-edit</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-card-text>
        <v-expand-transition>
            <v-card
                v-if="action"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;"
            >
                <v-card-title class="card-header page">
                    <v-icon color="primary" class="mr-1">fa fa-user-shield</v-icon> Update {{ updateTitle }}
                </v-card-title>
                <v-card-text class="pt-4">
                    <v-text-field
                        :autofocus="!$vuetify.breakpoint.smAndDown" 
                        v-if="action=='name'"
                        v-model="itemCopy.name"
                        outlined
                        label="Your Name"
                        :success="itemCopy.name !== item.name && itemCopy.name.length > 1"
                        placeholder="Ex: Kris"
                        class="mx-auto mt-4 mt-lg-5"
                        style="max-width: 400px;"
                        :rules="[rules.required]"
                        validate-on-blur
                        v-on:keyup.enter="(canSubmit) ? save() : ''"
                    ></v-text-field>
                    <p class="text-center caption mb-0" v-if="action=='name'">Will be displayed to your emergency contacts.</p>
                    <v-text-field
                        v-if="action=='phone'"
                        :autofocus="!$vuetify.breakpoint.smAndDown" 
                        v-model="itemCopy.phone"
                        outlined
                        label="Mobile Phone Number"
                        type="tel"
                        class="mx-auto mt-4 mt-lg-5"
                        style="max-width: 400px;"
                        :success="itemCopy.phone !== item.phone && itemCopy.phone.length == 14"
                        placeholder="Ex: (555) 555 - 5555"
                        :rules="[rules.required]"
                        validate-on-blur
                        v-mask="'(###) ###-####'"
                        v-on:keyup.enter="(canSubmit) ? save() : ''"
                    ></v-text-field>
                    <p class="text-center caption" v-if="action=='phone'">Must be able to receive text messages.<br>Carrier fees may apply.</p>
                    <v-select
                        v-if="action=='timezone'"
                        label="Your current Timezone"
                        outlined
                        v-model="itemCopy.timezone"
                        :items="timezones"
                        @change.once="tzWarn=true"
                        class="mx-auto mt-4 mt-lg-5"
                        style="max-width: 500px;"

                    />
                    <p class="text-center caption" v-html="tzHint" v-if="action=='timezone'" />
                    <Photo v-if="action=='photo'" :key="photoKey" :item="itemCopy" @selected="photo=$event" @removed="handlePhotoRemoved()" />
                </v-card-text>
                <v-card-actions class="card-actions cnc-actions mt-1">
                    <v-btn outlined text @click.native="action=null">Cancel</v-btn>
                    <v-spacer />
                    <v-btn color="primary" :loading="saving" :disabled="!canSubmit" class="px-5" @click.native="save()">Save Changes</v-btn>
                </v-card-actions>
            </v-card>
        </v-expand-transition>
        <Confirm v-if="tzWarn" title="Changing Timezones" subtitle="Changing timezones may affect your next check-in time." msg="You can view the time of your next check-in on your dashboard." confirmBtnText="Got it!" @confirmed="tzWarn=false" @closed="tzWarn=false" />
    </v-card>
</template>
<script>
    
import { mapState } from 'vuex'
import tzs from '@/data/timezones'
import Confirm from '@/components/common/Confirm'
import Photo from '@/components/common/Photo'

export default {
    name: 'UserProfileUpdate',
    props: {
        item: {
            required: true,
            type: Object
        }
    },
    components: {
        Confirm,
        Photo
    },
    computed: {
        ...mapState(['user']),
        canSubmit(){
            return true;
            // return this.item.name.length && this.item.phone.length == 14;
        },
        tzHint(){
            let hint = 'Used to send your check-ins at your local time. <br> Current local time is ';
            hint += this.$moment.tz(this.itemCopy.timezone).format('h:mma') + '.';
            return hint;
        },
        updateTitle(){
            if(this.action == 'name') return 'Display Name';
            if(this.action == 'phone') return 'Phone Number';
            if(this.action == 'timezone') return 'Your Timezone';
            if(this.action == 'photo') return 'Your Photo';

            return 'Profile';
        }
    },
    data: () => ({
        action: null,
        errors: null,
        formData: null,
        itemCopy: {},
        ready: false,
        rules: {
            required: value => !!value || 'Required.',
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        },
        photo: null,
        photoKey: 0,
        saving: false,
        thisTimezone: '',
        timezones: tzs.tzs,
        tzWarn: false,
    }),
    methods: {
        initData(){
            this.itemCopy = JSON.parse(JSON.stringify(this.item));
            this.formData = new FormData();
            this.getTimezone();
            this.ready = true;
        },
        getTimezone(){
            for(let i in this.timezones){
                if(this.timezones[i].value == this.user.timezone){
                    this.thisTimezone = this.timezones[i].text; 
                }
            }
        },        
        save(){ 
            this.saving = true;
            this.errors = null;
            this.formData.append('name', this.itemCopy.name);
            this.formData.append('phone', this.itemCopy.phone);
            this.formData.append('timezone', this.itemCopy.timezone);
            this.formData.append('_method', 'put');
            let request = {
                endpoint: '/profile', 
                method: 'post',
                data: this.formData,
                apiKey: this.user.api_token || null,
                header: { 'Content-Type': 'multipart/form-data' },
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.$eventHub.$emit('refreshUser');
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your personal information has been updated!'
                    });
                    this.$appFn.trackEvent({ action: 'user_setting_update', options: {
                        action_name: this.getActionTrackName()
                    }});
                    this.$emit('updated', res.data);
                    setTimeout(() => {
                        this.itemCopy = JSON.parse(JSON.stringify(res.data));
                        ++this.photoKey;
                    }, 1500);
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.saving = false;
                this.action = null;
            });
        },
        handleErrorResponse(res){
            this.errors = '';
            for(let i in res.data.errors){
                this.errors += res.data.errors[i];
            }
        },
        getActionTrackName(){
            if(this.action == 'name'){
                return 'Profile: Updated display name'; 
            }
            if(this.action == 'phone'){
                return 'Profile: Updated phone number'; 
            }
            if(this.action == 'timezone'){
                return 'Profile: Updated timezone'; 
            }
            return 'Updated user profile';
        },
        setFormImage(){
            fetch(this.photo)
            .then((response) => {
                return response.blob();
            }).then((blob) => {
                this.formData.append('image', blob);
                // this.uploadPhoto(true);
            });
        },
        handlePhotoRemoved(){
            if(this.itemCopy.image){
                this.photo = null;
                this.itemCopy.image = null;
                this.formData.append('image_removed', true);
                this.formData.delete('image');
            }
            this.$appFn.trackEvent({ action: 'user_setting_update', options: {
                action_name: 'Removed profile photo'
            }});
            this.save();
        },
    },
    mounted(){
		this.initData();
	},
    watch: {
        photo(){
            if(this.photo){
                this.setFormImage();
            }
        }
    }
}
</script>